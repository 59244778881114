import pomello from '@assets/images/pomello.png';
import pomello2x from '@assets/images/pomello@2x.png';
import pomodoroTimer from '@assets/images/pomodoro-timer.png';
import pomodoroTimer2x from '@assets/images/pomodoro-timer@2x.png';
import taco from '@assets/images/taco.png';
import taco2x from '@assets/images/taco@2x.png';
import target from '@assets/images/target.png';
import target2x from '@assets/images/target@2x.png';
import Modal from '@components/Modal';
import NoWordWrap from '@components/NoWordWrap';
import useModal from '@helpers/useModal';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import {
  Box,
  Button,
  Columns,
  Container,
  Content,
  Heading,
  Hero,
  Section,
} from 'react-bulma-components';
import { DOWNLOAD_PAGE } from '../../constants';
import * as styles from './HomeView.module.scss';

const HomeView: FC = () => {
  const {
    closeModal: closeTrelloModal,
    isOpen: trelloModalIsOpen,
    openModal: openTrelloModal,
  } = useModal();

  const {
    closeModal: closePomodoroModal,
    isOpen: pomodoroModalIsOpen,
    openModal: openPomodoroModal,
  } = useModal();

  return (
    <Content>
      <Hero className={styles.hero} color="primary">
        <Hero.Body>
          <Container textAlign="center">
            <Columns centered>
              <Columns.Column size={7} widescreen={{ size: 7 }}>
                <Heading desktop={{ textSize: 1 }} textSize={2} textWeight="normal">
                  The Pomodoro app for doers
                </Heading>
                <Heading renderAs="h2" tablet={{ textSize: 4 }} textSize={5} textWeight="normal">
                  Pomello turns your Trello cards into Pomodoro&reg; tasks. This little timer is
                  packed with features to supercharge your productivity for your daily tasks or
                  life-changing dreams.
                </Heading>
                <img
                  alt="Pomello screenshot"
                  className={styles.heroImage}
                  height={80}
                  src={pomello}
                  srcSet={`${pomello} 1x, ${pomello2x} 2x`}
                  width={271}
                />
              </Columns.Column>
            </Columns>
            <Button color="light" outlined renderAs={Link} size="medium" to={DOWNLOAD_PAGE}>
              Download Pomello
            </Button>
          </Container>
        </Hero.Body>
      </Hero>
      <Section>
        <Container textAlign="center">
          <Heading
            mb={6}
            mobile={{ display: 'hidden' }}
            renderAs="h2"
            size={3}
            subtitle
            textWeight="light"
          >
            Over 1,998,541 Pomodoros completed
          </Heading>
          <Columns centered>
            <Columns.Column size={4} desktop={{ size: 3 }}>
              <img
                alt="Trello mascot"
                height={180}
                src={taco}
                srcSet={`${taco} 1x, ${taco2x} 2x`}
                width={180}
              />
              <Heading renderAs="h3" textSize={5} mt={5}>
                Synced to Trello
              </Heading>
              <p>
                Uses Trello to organize your tasks. This means all the extra goodness that Trello
                has to offer.
              </p>
              <Button color="ghost" onClick={openTrelloModal}>
                What's Trello?
              </Button>
            </Columns.Column>
            <Columns.Column size={4} desktop={{ size: 3 }}>
              <img
                alt="Pomodoro timer"
                height={180}
                src={pomodoroTimer}
                srcSet={`${pomodoroTimer} 1x, ${pomodoroTimer2x} 2x`}
                width={180}
              />
              <Heading renderAs="h3" textSize={5} mt={5}>
                Proven effectiveness
              </Heading>
              <p>
                Employs the popular Pomodoro Technique, a tried and true method to supercharge your
                productivity.
              </p>
              <Button color="ghost" onClick={openPomodoroModal}>
                The Pomodoro Technique?
              </Button>
            </Columns.Column>
            <Columns.Column size={4} desktop={{ size: 3 }}>
              <img
                alt="Bullseye"
                height={180}
                src={target}
                srcSet={`${target} 1x, ${target2x} 2x`}
                width={180}
              />
              <Heading renderAs="h3" textSize={5} mt={5}>
                Distraction-free design
              </Heading>
              <p>
                Uses minimal screen real estate with its simple but intuitive interface. Yet its
                still packed with tons of features.
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      <Hero color="link">
        <Hero.Body>
          <Columns centered>
            <Columns.Column mobile={{ display: 'hidden' }} size={3}>
              <Heading desktop={{ textSize: 5 }} renderAs="p" textSize={6}>
                "Thank you for this amazing app. It helps me keep track of time and get my tasks
                done on a daily basis."
              </Heading>
              <span>M.A. Constantin</span>
            </Columns.Column>
            <Columns.Column size={3}>
              <Heading desktop={{ textSize: 5 }} renderAs="p" textSize={6}>
                "After using Pomello for about a month now, I must say, it single handedly
                skyrocketed my productivity."
              </Heading>
              <span>Paula Shin</span>
            </Columns.Column>
            <Columns.Column mobile={{ display: 'hidden' }} size={3}>
              <Heading desktop={{ textSize: 5 }} renderAs="p" textSize={6}>
                "This is the best thing that ever happened to me. Thank you for this tool. It is
                helping me focus so much better."
              </Heading>
              <span>Ruan Pienaar</span>
            </Columns.Column>
          </Columns>
        </Hero.Body>
      </Hero>
      <Section>
        <Container textAlign="center">
          <Columns centered>
            <Columns.Column desktop={{ size: 7 }} size={9}>
              <Heading desktop={{ textSize: 3 }} textSize={4} subtitle>
                Thousands of people rely on Pomello to stay productive and accomplish more.{' '}
                <NoWordWrap>Find out why.</NoWordWrap>
              </Heading>
              <p>Pomello is available for free on Mac, Windows, and Linux.</p>
              <Button color="primary" renderAs={Link} size="medium" mt={5} to={DOWNLOAD_PAGE}>
                Get Started
              </Button>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      {trelloModalIsOpen && (
        <Modal closeModal={closeTrelloModal} contentLabel="What's Trello?">
          <Box>
            <Content>
              <h4>What's Trello?</h4>
              <p>
                Trello is a project management tool that organizes projects into lists and cards. It
                gives a visual overview of what's being worked on and where something is in a
                process. Its versatility makes it an effective tool for organizing large projects or
                managing daily tasks.
              </p>
              <Button mt={3} onClick={closeTrelloModal} size="small">
                Close
              </Button>
            </Content>
          </Box>
        </Modal>
      )}
      {pomodoroModalIsOpen && (
        <Modal closeModal={closePomodoroModal} contentLabel="The Pomodoro Technique?">
          <Box>
            <Content>
              <h4>The Pomodoro Technique?</h4>
              <p>
                The Pomodoro Technique is a productivity method that breaks down tasks into timed
                intervals. These intervals&mdash;called pomodoros&mdash;are traditionally 25 minutes
                long and separated with 5 minute breaks. The technique is easy to adopt and applies
                to any task or project.
              </p>
              <Button mt={3} onClick={closePomodoroModal} size="small">
                Close
              </Button>
            </Content>
          </Box>
        </Modal>
      )}
    </Content>
  );
};

export default HomeView;
