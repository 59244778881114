import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DefaultTemplate from '@templates/DefaultTemplate';
import HomeView from '@views/HomeView';
import React, { FC } from 'react';

const IndexPage: FC = () => {
  const { t } = useTranslation('pages');

  return (
    <DefaultTemplate navBarShadow>
      <PageMeta description={t('homePageDescription')} title={t('homePage')} />
      <HomeView />
    </DefaultTemplate>
  );
};

export default IndexPage;
